<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="STAFF_PERMISSION"
      tooltip-title="STAFF_PERMISSION"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="minHieght80 flex-col">
      <div v-if="!loading">
        <CheckBox
          v-for="(setting, index) in settingsList"
          :key="index"
          class="mt-6"
          :title="index"
          parent-module="staff"
          :modules-array="setting"
          :table-head-array="tableHeadArray"
          @updateSettings="getSettings"
        />
      </div>
      <Loader v-else class="mt-28" :content="true" />
    </div>
  </div>
</template>

<script>
/**
 * @notes IMPORTANT NOTES
 * index ACTION FOR VIEW
 * create ACTION FOR MARK
 *  */
/* COMPONENTS */
import TitleWrapper from '@components/TitleWrapper.vue'
import CheckBox from '@src/components/Settings/attandance-settings/PermissionSettingsCheckbox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { removeExtraRolePermissions } from '@utils/permissions'
import { EXCLUDE_ROLES } from '@src/constants/permission-constants.js'

/* 3rd party Component */
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    TitleWrapper,
    CheckBox,
    Loader,
  },
  page: {
    title: 'System Settings | Staff Settings',
    meta: [
      {
        name: 'description',
        content: 'Staff Settings',
      },
    ],
  },
  data: function () {
    return {
      settingsList: [],
      tableHeadArray: ['Settings', 'View', 'Mark'],
      loading: false,
      excludeRoleSettings: EXCLUDE_ROLES,
    }
  },

  computed: {
    ...mapState('layout', [
      'currentCampusScope',
      'currentSectionScope',
      'activeRole',
      'currentClassScope',
    ]),
  },
  watch: {
    currentCampusScope: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    currentSectionScope: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    activeRole: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    currentClassScope: {
      handler() {
        this.getSettings()
      },
    },
  },
  mounted() {
    this.getSettings()
  },

  methods: {
    async getSettings() {
      this.loading = true
      const [res, err] = await this.getStaffSettings()
      removeExtraRolePermissions(this.excludeRoleSettings, res.data)

      this.settingsList = res.data
      this.loading = false
    },
    ...mapActions('settings', ['getStaffSettings']),
  },
}
</script>
